import React from "react"
import Layout from "../components/layout"
import Slanted, { NormalHeading } from "../components/layout/slanted"
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO"

const Program = ({ data, pageContext: { next, previous } }) => {
  const {
    wpProgram: {
      title,
      content,
      kyrkoProgramFields: { attSatser },
      featuredImage,
    },
  } = data
  let imageData = null
  if (typeof featuredImage !== undefined && featuredImage) {
    imageData = featuredImage.node.localFile.childImageSharp.fluid
  }
  return (
    <Layout>
      <SEO image={imageData}></SEO>
      <Slanted imageData={imageData} useStandardImage>
        <NormalHeading white>{title}</NormalHeading>
      </Slanted>
      <Slanted innerClass="bg-white">
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          <h3>Vi vill:</h3>
          <ul>
            {attSatser.map(({ sats }, index) => (
              <li key={index}>{sats}</li>
            ))}
          </ul>
          <div className="flex flex-col  md:flex-row justify-between mt-10">
            <div>
              {previous && (
                <div className="flex flex-col mb-4">
                  <Link to={previous.uri}>
                    &#60;&#60; Förra <br></br> {previous.title}
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col text-right">
              {next && (
                <Link to={next.uri}>
                  Nästa &#62;&#62; <br></br>
                  {next.title}
                </Link>
              )}
            </div>
          </div>
          <div className="mt-8 text-center text-lg">
            <Link to="/manifest">Tillbaka till manifestet!</Link>
          </div>
        </div>
      </Slanted>
      <Slanted useStandardImage>
        <NormalHeading white align="center">
          Håller du med?
        </NormalHeading>
        <div className="mx-auto mt-10">
          <Link to="/sa-rostar-du" className="main-button">
            Läs om hur du röstar på oss!
          </Link>
        </div>
      </Slanted>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    wpProgram(slug: { eq: $slug }) {
      title
      content
      kyrkoProgramFields {
        attSatser {
          sats
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default Program
